import { Box } from '@mui/material';
import { useLocation } from '@reach/router';

import Certification from 'modules/ui/components/Certification';

const CertsPage = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const id = params.get('id');

  return (
    <Box display="flex" justifyContent="center" m={2}>
      <Box maxWidth={750} width="100%">
        <Certification id={id}></Certification>
      </Box>
    </Box>
  );
};

export default CertsPage;
